'use strict';
var jsonp = require('jsonp');


/* Poçoweb App Switcher
 * ====================
 *
 * Input: app - uma label representando o app executando o request;
 * args - um objeto contendo:
 * - target; um DOMElement, onde o switcher será inserido
 * - pocoweb_url; string representando a url do poçoweb
 * - extra_links; objeto opcional contendo relação de links extras para
 *   serem inseridos. Por exemplo: {"Admin": "/admin", "Sair", "/logout"}
 *   vai criar <a href="/admin">Admin</a> e <a href="/logout">Sair</a>.
 *   São aceitos tanto strings como href quanto funções
 *   (nesse caso, um onClick será usado)
 */

function loadSwitcher(app, args) {
  try {
    if(!args.pocoweb_url) {
      // default
      args.pocoweb_url = 'https://pocoweb.cenpes.petrobras.com.br/';
    }
    else if(args.pocoweb_url.startsWith('https://') || args.pocoweb_url.startsWith('http://')){
      // utitliza base url, ignora se caminho relativo for passado
      args.pocoweb_url = args.pocoweb_url.split('/').slice(0, 3).join('/') + '/';
    }

    var switcher = document.createElement('div');
    switcher.className = 'switcher';
    switcher.style.display = 'none'; // escondido até carregar estilos


    // load style, não opcional
    var link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = args.pocoweb_url + 'assets/css/pocoweb-switcher.css?v=3';

    // insere como primeiro, para permitir que seja redefinido
    document.head.insertBefore(link, document.head.firstChild);


    // Primeiro, criamos o nosso botão (vai abrir o switcher)
    var toggleBtn = createToggleBtn(args.pocoweb_url);
    toggleBtn.addEventListener('click', toggleDropdown(switcher));
    document.body.addEventListener('click', toggleDropdown(switcher, true));

    // Então, criamos o dropdown já com os primeiros links
    // e carregamos assincronamente a lista de apps que o user tem acesso
    var dropdown = createDropdown(args.extra_links || {});
    var permissionsURL = args.pocoweb_url + 'api/public/permissions/?app=' + app;

    jsonp(permissionsURL, {timeout: 15000}, function(err, data) {
      if(!err) {
        createAppLinks(dropdown, args.pocoweb_url, data);
      } else {
        console.error('FAIL download permissions!', err);
        fallbackDropdown(dropdown, args.pocoweb_url);
      }
    });

    // Agora é colocar tudo no lugar
    switcher.appendChild(toggleBtn);
    switcher.appendChild(dropdown);
    args.target.appendChild(switcher);
  }
  catch(e) {
    if(window.console && window.console.error) {
      window.console.error('Não foi possível carregar app switcher', e);
    }
  }
}


// Factories
// =========

function createToggleBtn(pocoweb_url) {
  var a = document.createElement('a');
  a.className = 'switcher__btn';

  var icon = document.createElement('img');
  icon.src = pocoweb_url + 'assets/img/appswitchericon.png';
  a.appendChild(icon);

  return a;
}


function createDropdown(links) {
  var dropdown = document.createElement('div');
  dropdown.className = 'switcher__dropdown';

  // Elemento provisório, será
  // removido assim que o callback do
  // request for efetuado
  var loadingIcon = document.createElement('i');
  loadingIcon.className = 'fa fa-spinner fa-spin';
  var loading = document.createElement('div');
  loading.className = 'switcher__dropdown__loading';
  loading.appendChild(loadingIcon);
  dropdown.appendChild(loading);

  Object.keys(links).map(function(label) {
    var target = links[label];
    var a = document.createElement('a');
    a.className = 'switcher__dropdown__btn';
    a.text = label;

    if(typeof target === 'function') {
      a.addEventListener('click', target);
    } else {
      a.href = target;
    }

    dropdown.appendChild(a);
  });

  return dropdown;
}


function createAppLinks(dropdown, pocoweb_url, data) {
  // firstChild é o loading
  var firstChild = dropdown.firstChild;

  // Sempre exibe icone para home primeiro (é útil tb para caso quando for vazio)
  dropdown.insertBefore(createPWLink(pocoweb_url), firstChild);

  data.forEach(function(app) {

    var label;
    label = document.createElement('p');
    label.className = 'switcher__dropdown__label';
    label.innerHTML = app.label;

    var a = document.createElement('a');
    a.className = 'switcher__dropdown__btn';
    a.href = app.href;
    a.appendChild(label);

    dropdown.insertBefore(a, firstChild);
  });

  dropdown.removeChild(firstChild);
}

function fallbackDropdown(dropdown, pocoweb_url) {
  // TODO: Por default, exibe apenas uma logo de volta pro Poçoweb.
  var firstChild = dropdown.firstChild;
  dropdown.insertBefore(createPWLink(pocoweb_url), firstChild);
  dropdown.removeChild(firstChild);
}


/**
 * Icone para home do PocoWeb (Drive).
 */
function createPWLink(pocoweb_url) {

  var label;
  label = document.createElement('p');
  label.className = 'switcher__dropdown__label';
  label.innerHTML = 'Poçoweb';

  var a = document.createElement('a');
  a.className = 'switcher__dropdown__btn';
  a.href = pocoweb_url;
  a.appendChild(label);
  return a;
}


// Event handlers
// ==============

function toggleDropdown(container, forceClose) {
  return function(event) {
    if(forceClose) {
      container.className = 'switcher';
    } else {
      container.classList.toggle('switcher--open');
    }

    if(typeof forceClose === 'undefined') {
      event.stopPropagation();
    }
  };
}


window.PocowebSwitcher = loadSwitcher;
